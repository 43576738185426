import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiUserService } from '../../services/api-user/api-user.service';
import { MatDialogRef } from '@angular/material/dialog';
import { StoreHelper } from '../../helpers/store/store.helper';

@Component({
    selector: 'app-delete-account-modal',
    templateUrl: './delete-account-modal.component.html',
    styleUrls: ['./delete-account-modal.component.scss']
})
export class DeleteAccountModalComponent {

    form = new FormGroup({
        password: new FormControl('', { validators: [Validators.required] })
    });

    constructor(private userService: ApiUserService,
                private storeHelper: StoreHelper,
                private dialogRef: MatDialogRef<DeleteAccountModalComponent>) {
    }

    async handleSubmit() {
        const { password } = this.form.value;

        this.userService.delete('me', {}, { password: password as string })
            .subscribe(() => {
                this.storeHelper.clearAll();
                this.dialogRef.close();
            });
    }
}
