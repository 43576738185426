import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChatMessage } from '../../dtos/chat-message';
import { Store } from '@ngrx/store';
import { UserModuleSelectors, UserModuleState } from '../../../user/user.state';
import { Subscription } from 'rxjs';
import { User } from '../../../user/dtos/user';

@Component({
    selector: 'app-chat-ui',
    templateUrl: './chat-ui.component.html',
    styleUrls: ['./chat-ui.component.scss']
})
export class ChatUiComponent implements OnInit, OnDestroy {

    @Input() messages: ChatMessage[] = [];
    @Output() messagesChange = new EventEmitter<ChatMessage[]>();
    @Output() send = new EventEmitter<{ id: string, message: string }>();

    user: User;
    userSubscription: Subscription;

    form = new FormGroup({
        message: new FormControl('', { validators: [Validators.required] }),
    });

    constructor(private store: Store<UserModuleState>) {
    }

    ngOnInit(): void {
        this.userSubscription = this.store.select(UserModuleSelectors.selectUser).subscribe(user => this.user = user);
    }

    ngOnDestroy(): void {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

    sendMessage() {
        const { message } = this.form.value;
        if (message) {
            const createdAt = new Date();
            const tempId = String(createdAt.getTime());

            // Add temporary message
            this.messages = [...this.messages, {
                id: String(tempId),
                message,
                author: this.user,
                status: 'LOADING',
                createdAt: createdAt.toISOString(),
            }];
            this.messagesChange.emit(this.messages);

            // Send message to transmit it to the API
            this.send.emit({ id: tempId, message });

            this.form.reset();
        }
    }

    retry({ id, message }: ChatMessage) {
        this.messages = this.messages.map(m =>
            m.id === id ? ({ ...m, status: 'LOADING' }) : m
        );
        this.messagesChange.emit(this.messages);

        // Re send message to transmit it to the API
        this.send.emit({ id, message });
    }

}
