import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiAuthService } from '../../services/api-auth/api-auth.service';
import { ApiUserService } from '../../services/api-user/api-user.service';
import { Router } from '@angular/router';
import { AuthHelper } from '../../helpers/auth/auth.helper';
import { LS_PERSISTED_AUTH } from '../../../shared/global.constants';
import { MatDialog } from '@angular/material/dialog';
import { CreateResetCodeComponent } from '../../components/create-reset-code/create-reset-code.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {

    form = new FormGroup({
        email: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.email] }),
        password: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
        stayLogged: new FormControl(false)
    });

    constructor(private apiAuthService: ApiAuthService,
                private apiUserService: ApiUserService,
                private authHelper: AuthHelper,
                private router: Router,
                private dialog: MatDialog) {
    }

    login() {
        this.form.disable();

        const { email, password, stayLogged } = this.form.value;
        const storage = stayLogged ? localStorage : sessionStorage;

        this.apiAuthService.create({ email, password }).subscribe({
            next: async (jwtToken) => {
                // Save login session or persisted login locally
                localStorage.setItem(LS_PERSISTED_AUTH, stayLogged ? 'true' : 'false');

                // Save authentication
                await this.authHelper.saveAuthentication(storage, jwtToken);

                // Reset form and redirect
                this.form.reset();
                await this.router.navigateByUrl('/collections');
            },
            error: () => {
                this.form.enable();
            }
        });
    }

    async createResetCode() {
        this.dialog.open(CreateResetCodeComponent);
    }
}
