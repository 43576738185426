// USER

export const API_RESOURCE_AUTH = 'auth';
export const API_RESOURCE_USERS = 'users';

// BOOK
export const API_RESOURCE_ACTUALITIES = 'actualities';
export const API_RESOURCE_SERIES = 'series';
export const API_RESOURCE_SERIES_STATUS = 'series/status';
export const API_RESOURCE_BOOKS = 'books';
export const API_RESOURCE_BOOK_RATINGS = 'books/:isbn/ratings';
export const API_RESOURCE_USER_RATINGS = 'users/:id/ratings';
export const API_RESOURCE_CATEGORIES = 'categories';
export const API_RESOURCE_EDITIONS = 'editions';
export const API_RESOURCE_GENDERS = 'genders';
export const API_RESOURCE_USER_SERIES = 'users/:username/series';

// COLLECTION
export const API_RESOURCE_COLLECTIONS = 'users/:username/collections';
export const API_RESOURCE_COLLECTIONS_BY_BOOK = API_RESOURCE_COLLECTIONS + '/:type/books';
export const API_RESOURCE_COLLECTIONS_BY_SERIES = API_RESOURCE_COLLECTIONS + '/:type/series';

// RELEASE
export const API_RESOURCE_RELEASES = 'releases';

// SHARED
export const API_RESOURCE_ALERTS = 'alerts';
export const API_RESOURCE_USER_NOTIFICATIONS = 'users/me/notifications';
export const API_RESOURCE_CONTACT = 'contact';

// SUPPORT
export const API_RESOURCE_USER_TICKETS = 'users/me/tickets';
export const API_RESOURCE_TICKETS = 'tickets';
export const API_RESOURCE_TICKET_COMMENTS = 'tickets/:ticketId/comments';
export const API_RESOURCE_TICKET_STATUSES = 'ticket-statuses';

// CACHE
export const CACHE_TTL_TWO_HOURS = 60 * 60 * 2; // 2 hours
export const CACHE_TTL_DEFAULT = 60 * 60 * 12; // 12 hours
export const CACHE_TTL_ONE_MONTH = 60 * 60 * 24 * 31; // 1 month

// ERRORS
export const API_ERR_BOOK_ALREADY_IN_COLLECTION = 'ERR_BOOK_ALREADY_IN_COLLECTION';
export const API_ERR_BOOK_NOT_IN_COLLECTION = 'ERR_BOOK_NOT_IN_COLLECTION';

// OPTIONS
export interface CacheOptions {
    group: string;
    ttl: number;
}

export class ApiGetOptions {
    http?: any;
    cache: CacheOptions;
}

interface Filter {
    [key: string]: string | number;
}

export interface FindOptions {
    q?: string | Filter;
    params?: Record<string, string | number>;
    query?: Record<string, string | number | boolean>;
    ttl?: number;
}
