<div class="pagination">
    <button mat-flat-button class="pagination__first-item pagination__cta background-transparent" *ngIf="currentPage > 2"
            (click)="changePage.emit(1)">
        <mat-icon aria-hidden="false" aria-label="Previous" fontIcon="keyboard_double_arrow_left"></mat-icon>
    </button>
    <button mat-flat-button class="pagination__current-item pagination__cta background-transparent" *ngIf="currentPage > 1"
            (click)="changePage.emit(currentPage - 1)">
        {{currentPage - 1}}
    </button>
    <button mat-flat-button class="pagination__current-item pagination__cta cta-shadow text-light" color="primary">
        {{currentPage}}
    </button>
    <button mat-flat-button class="pagination__next-item pagination__cta background-transparent" *ngIf="currentPage < lastPage"
            (click)="changePage.emit(currentPage + 1)">
        {{currentPage + 1}}
    </button>
    <button mat-flat-button class="pagination__last-item pagination__cta background-transparent" *ngIf="currentPage < (lastPage - 1)"
            (click)="changePage.emit(lastPage)">
        <mat-icon aria-hidden="false" aria-label="Next" fontIcon="keyboard_double_arrow_right"></mat-icon>
    </button>
</div>
