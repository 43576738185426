import { Pipe } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Pipe({
    name: 'translateOrDefault',
    pure: false
})
export class TranslateOrDefaultPipe extends TranslatePipe {

    override transform(query: string, defaultValue: string, ...args: any[]): any {
        const value = super.transform(query, ...args);
        return value !== query ? value : defaultValue;
    }

}
