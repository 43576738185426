import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
    },
    {
        path: '',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
    },
    {
        path: '',
        loadChildren: () => import('./collection/collection.module').then(m => m.CollectionModule)
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'support',
        loadChildren: () => import('./support/support.module').then(m => m.SupportModule)
    },
    {
        path: 'legal',
        loadChildren: () => import('./legal/legal.module').then(m => m.LegalModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
