import { createFeatureSelector, createSelector } from '@ngrx/store';
import { userFeatureKey, userReducer } from './reducers/user.reducer';
import { userContextFeatureKey, userContextReducer } from './reducers/user-context.reducer';
import { UserSelectors } from './selectors/user.selectors';
import { UserContextSelectors } from './selectors/user-context.selectors';
import { jwtFeatureKey, jwtReducer } from './reducers/jwt.reducer';
import { JwtSelectors } from './selectors/jwt.selectors';
import { JwtTokenOutput } from './dtos/outputs/jwt-token.output';
import { User } from './dtos/user';
import { UserContextOutput } from './dtos/outputs/user-context.output';
import { UserContextEffects } from './effects/user-context.effects';

export const userModuleFeatureKey = 'UserModule';

export interface UserModuleState {
    [jwtFeatureKey]: JwtTokenOutput;
    [userFeatureKey]: User;
    [userContextFeatureKey]: UserContextOutput;
}

export const userModuleReducers = {
    [jwtFeatureKey]: jwtReducer,
    [userFeatureKey]: userReducer,
    [userContextFeatureKey]: userContextReducer,
};

export const userModuleEffects = [UserContextEffects];

const selectState = createFeatureSelector<UserModuleState>(userModuleFeatureKey);
const selectJWT = createSelector(selectState, JwtSelectors.selectState);
const selectUser = createSelector(selectState, UserSelectors.selectState);
const selectUserContext = createSelector(selectState, UserContextSelectors.selectState);

export const UserModuleSelectors = {
    selectState,
    selectJWT,
    selectUser,
    selectUserContext,
};
