import { Pipe, PipeTransform } from '@angular/core';
import { GenderOutput } from '../../dtos/outputs/gender.output';

@Pipe({
    name: 'censor'
})
export class CensorPipe implements PipeTransform {

    transform(genders: GenderOutput[]): boolean {
        if (!genders) {
            return false;
        }
        return !!genders.filter(g => ['Erotic', 'Hentai', 'Ecchi'].includes(g.labelEn)).length;
    }

}
