import { createAction, props } from '@ngrx/store';
import { UserContextOutput } from '../dtos/outputs/user-context.output';
import { CollectionTypeEnum } from '../../collection/collection-type.enum';

interface Counter {
    cnt: number;
}

const initOrUpdate = createAction('[UserContext] Init Or Update Context', props<{ context: UserContextOutput }>());

const incrementBooksByColTypeAndCategory = createAction('[UserContext] Increment Books By ColType And Category',
    props<{ colType: CollectionTypeEnum; categoryId: string; cnt: number }>()
);

const decrementBooksByColTypeAndCategory = createAction('[UserContext] Decrement Books By ColType And Category',
    props<{ colType: CollectionTypeEnum; categoryId: string; cnt: number }>()
);

const updateNotifications = createAction('[UserContext] Update Notification', props<Counter>());
const updateNotificationsNotRead = createAction('[UserContext] Update Notification', props<Counter>());

const decrementNotifications = createAction('[UserContext] Decrement Notifications', props<Counter>());
const decrementNotificationsNotRead = createAction('[UserContext] Decrement Notifications Read', props<Counter>());

const reset = createAction('[UserContext] Reset');

export const UserContextActions = {
    initOrUpdate,
    incrementBooksByColTypeAndCategory,
    decrementBooksByColTypeAndCategory,
    updateNotifications,
    updateNotificationsNotRead,
    decrementNotifications,
    decrementNotificationsNotRead,
    reset
};
