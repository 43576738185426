import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../services/translation/translation.service';
import { LANG_EN, LANG_ES } from '../../global.constants';

interface LangLabels {
    labelFr: string;
    labelEn: string;
    labelEs: string;
}

@Pipe({
    name: 'switchLangLabel',
    pure: false,
})
export class SwitchLangLabelPipe implements PipeTransform {

    constructor(private translationService: TranslationService) {}

    transform(value: any & LangLabels): string {
        if (!value) {
            return '';
        }

        const lang = this.translationService.getCurrentLang();

        switch (lang) {
            case LANG_EN:
                return value.labelEn;

            case LANG_ES:
                return value.labelEs;

            default:
                return value.labelFr;
        }
    }

}
