import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { UserContextActions } from '../actions/user-context.action';
import { UserContextOutput } from '../dtos/outputs/user-context.output';

export const userContextFeatureKey = 'UserContext';

// @ts-ignore
export const initialState: UserContextOutput = null;

const reducer: ActionReducer<UserContextOutput> = createReducer(
    initialState,
    on(UserContextActions.initOrUpdate, (state, { context }) => context),
    on(UserContextActions.updateNotifications, (state, { cnt }) => ({ ...state, nbNotifications: cnt })),
    on(UserContextActions.updateNotificationsNotRead, (state, { cnt }) => ({ ...state, nbNotificationsNotRead: cnt })),
    on(UserContextActions.decrementNotifications, (state, { cnt }) => ({
        ...state,
        nbNotifications: state.nbNotifications - cnt
    })),
    on(UserContextActions.decrementNotificationsNotRead, (state, { cnt }) => ({
        ...state,
        nbNotificationsNotRead: state.nbNotificationsNotRead - cnt
    })),
    on(UserContextActions.reset, () => initialState)
);

export function userContextReducer(state: UserContextOutput | undefined, action: Action) {
    return reducer(state, action);
}

