import { Component, Input } from '@angular/core';
import { SN_DISCORD, SN_FB, SN_INSTA } from '../../global.constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

    @Input() displaySocialNetworks: boolean;

    socialNetworks = {
        fb: SN_FB,
        insta: SN_INSTA,
        discord: SN_DISCORD,
    };
}
