import { Pipe, PipeTransform } from '@angular/core';
import { ActivationResult } from '../../pages/activation-account/activation-account.component';

@Pipe({
    name: 'checkActivation'
})
export class CheckActivationPipe implements PipeTransform {

    transform(value: ActivationResult | null): string {
        switch (value) {
            case ActivationResult.Activated:
                return 'USER.PIPES.CHECK_ACTIVATION.ACTIVATED';

            case ActivationResult.AlreadyActivated:
                return 'USER.PIPES.CHECK_ACTIVATION.ALREADY_ACTIVATED';

            case ActivationResult.NotActivated:
                return 'USER.PIPES.CHECK_ACTIVATION.NOT_ACTIVATED';

            default:
                return 'COMMON.LOADING';
        }
    }

}
