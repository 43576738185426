import { Component, EventEmitter, HostListener, Input, OnChanges, Output } from '@angular/core';

@Component({
    selector: 'app-footer-pagination',
    templateUrl: './footer-pagination.component.html',
    styleUrls: ['./footer-pagination.component.scss']
})
export class FooterPaginationComponent implements OnChanges {

    @Input() totalSentence: string;
    @Input() currentPage: number;
    @Input() lastPage: number;

    @Output() changePage = new EventEmitter<number>();

    scrolledBottom = false;

    ngOnChanges() {
        // Workaround to initialize sticky footer after view init when changes
        setTimeout(() => this.initializeSticky());
    }

    @HostListener("window:scroll")
    onScroll = () => this.initializeSticky();

    initializeSticky() {
        const page = document.getElementById('page');
        if (page) {
            if ((window.innerHeight + window.scrollY) >= (page.scrollHeight + 15)) {
                this.scrolledBottom = true;
            } else if ((window.innerHeight + window.scrollY) < (page.scrollHeight - 20)) {
                this.scrolledBottom = false;
            }
        }
    }

}
