import { Component, OnInit } from '@angular/core';
import { TranslationService } from './shared/services/translation/translation.service';
import { AuthHelper } from './user/helpers/auth/auth.helper';
import { LS_JWT, LS_PERSISTED_AUTH } from './shared/global.constants';
import { JwtTokenOutput } from './user/dtos/outputs/jwt-token.output';
import { Actions, ofType } from '@ngrx/effects';
import { AppActions } from './app.actions';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'MyBooksMemory';
    homeActive = true;

    mainLoading = true;
    pageLoading = false;

    constructor(private translationService: TranslationService,
                private actions$: Actions,
                private authHelper: AuthHelper) {
    }

    async ngOnInit() {
        this.translationService.initializeLang();

        const persistedAuth = localStorage.getItem(LS_PERSISTED_AUTH) === 'true';
        const storage = persistedAuth ? localStorage : sessionStorage;
        const jwt = storage.getItem(LS_JWT);

        if (jwt) {
            await this.authHelper.saveAuthentication(storage, JSON.parse(jwt) as JwtTokenOutput);
        }

        this.mainLoading = false;

        this.actions$.pipe(ofType(AppActions.changeLoaderStatus)).subscribe(({ enable }) => this.pageLoading = enable);
    }
}
