import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '../../dtos/user';
import { filter, Observable, Subscription, zip } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserModuleSelectors, UserModuleState } from '../../user.state';
import { UserActions } from '../../actions/user.action';
import { ApiUserService } from '../../services/api-user/api-user.service';
import { UserContextOutput } from '../../dtos/outputs/user-context.output';
import { ApiCategoryService } from '../../../book/services/api-category/api-category.service';
import { CategoryOutput } from '../../../book/dtos/outputs/category.output';
import { ALL_COLLECTIONS } from '../../../shared/global.constants';
import { CollectionTypeEnum } from '../../../collection/collection-type.enum';
import {
    FormModifyPasswordModalComponent
} from '../../modals/form-modify-password-modal/form-modify-password-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Actions } from '@ngrx/effects';
import { TranslationService } from '../../../shared/services/translation/translation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../../environments/environment';
import { DeleteAccountModalComponent } from '../../modals/delete-account-modal/delete-account-modal.component';

export interface RankingItem {
    label: string;
    cnt: number;
}

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {
    publicProfileUrl = environment.publicProfileUrl;
    allCollections = ALL_COLLECTIONS;
    colSelected: CollectionTypeEnum | undefined = this.allCollections[0];
    userColTypes: CollectionTypeEnum[] = [];

    user$: Observable<User> = this.store.select(UserModuleSelectors.selectUser);
    context: UserContextOutput;
    contextSubscription: Subscription;
    categories: CategoryOutput[] = [];

    topRankingItems: RankingItem[] = [];

    constructor(private dialog: MatDialog,
                private apiUserService: ApiUserService,
                private apiCategoryService: ApiCategoryService,
                private snackBar: MatSnackBar,
                private translationService: TranslationService,
                private actions$: Actions,
                private store: Store<UserModuleState>) {
    }

    ngOnInit(): void {
        this.contextSubscription = zip(
            this.store.select(UserModuleSelectors.selectUserContext).pipe(filter(context => !!context)),
            this.apiCategoryService.findAll()
        ).subscribe(([context, categories]) => {
            this.context = context;
            this.categories = categories;

            // The "nbBooksByCategoryAndCollection" property only contains data from at least 1 book,
            // so we may not receive a collection type (e.g. Owned)
            this.userColTypes = this.allCollections
                .filter(colType => context.nbBooksByCategoryAndCollection.map(item => item.type).includes(colType));

            // Get first collection which is in userColTypes
            this.colSelected = this.allCollections.find(colType => this.userColTypes.includes(colType));

            this.initializeTopRanking();
        });
    }

    ngOnDestroy(): void {
        if (this.contextSubscription) {
            this.contextSubscription.unsubscribe();
        }
    }

    handleColSelected(colType: CollectionTypeEnum) {
        this.colSelected = colType;
        this.initializeTopRanking();
    }

    initializeTopRanking() {
        this.topRankingItems = this.context.nbBooksByCategoryAndCollection
            .filter(item => item.type === this.colSelected)
            .map(({ categoryId, cnt }) => {
                const category = this.categories.find(c => c.id === categoryId);
                return { label: category?.labelFr || 'N/C', cnt };
            });
    }

    setPublic(isCurrentPublic: boolean) {
        const isPublic = !isCurrentPublic;
        this.apiUserService.update('me', { isPublic }).subscribe(() => {
            this.store.dispatch(UserActions.updatePublicProfile({ isPublic }));
        });
    }

    openEditPasswordDialog() {
        const dialogRef = this.dialog.open(FormModifyPasswordModalComponent);

        dialogRef.afterClosed().subscribe(async (input) => {
            if (input) {
                this.apiUserService.update('me', {
                    password: input.oldPassword,
                    newPassword: input.newPassword
                }).subscribe(async () => {
                    this.snackBar.open(
                        await this.translationService.getTranslation('Mot de passe changé'),
                        await this.translationService.getTranslation('COMMON.CLOSE'),
                    );
                });
            }
        });
    }

    openDeleteAccountDialog() {
        return this.dialog.open(DeleteAccountModalComponent);
    }
}
