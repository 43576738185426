import { Pipe, PipeTransform } from '@angular/core';
import { Star } from '../../utils';

@Pipe({
    name: 'stylingStarByRate'
})
export class StylingStarByRatePipe implements PipeTransform {

    transform(star: Star, rate: number): 'star-outline' | 'star-half' | 'star' {
        if (!star.checked ? '-outline' : '') {
            return 'star-outline';
        }

        return (rate < star.num && Math.round(rate) === star.num) ? 'star-half' : 'star';
    }

}
