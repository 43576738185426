import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-nothing-found',
    templateUrl: './nothing-found.component.html',
    styleUrls: ['./nothing-found.component.scss']
})
export class NothingFoundComponent {

    @Input() title: string;
    @Input() description: string;

}
