import { Component, OnInit } from '@angular/core';
import { TranslationService } from '../../services/translation/translation.service';

interface Lang {
    code: string;
    label: string;
}

@Component({
    selector: 'app-select-translation',
    templateUrl: './select-translation.component.html',
    styleUrls: ['./select-translation.component.scss']
})
export class SelectTranslationComponent implements OnInit {

    languages: Lang[] = [
        {
            code: 'fr',
            label: 'Français',
        },
        {
            code: 'en',
            label: 'English',
        },
        {
            code: 'es',
            label: 'Español',
        }
    ];
    currentLang!: string;

    constructor(private translationService: TranslationService) {
    }

    ngOnInit(): void {
        this.currentLang = this.translationService.getCurrentLang();
    }

    handleSelectLang({ code }: Lang) {
        this.translationService.use(code);
        this.currentLang = code;
    }

}
