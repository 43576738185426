import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Colors, Star } from '../../utils';

@Component({
    selector: 'app-star-rating',
    templateUrl: './star-rating.component.html',
    styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnInit, OnChanges {

    @Input() size: 'small' | 'large' = 'large';
    @Input() color: Colors = 'primary';
    @Input() isReadonly = false;
    @Input() nbStars = 5;
    @Input() rate: number;
    @Output() rateChange = new EventEmitter<number>();

    stars: Star[] = [];

    ngOnInit() {
        const color = this.getColor(this.rate);

        for (let i = 0; i < this.nbStars; i++) {
            const num = i + 1;
            const checked = num <= Math.round(this.rate);

            this.stars.push({
                num,
                checked,
                color: checked ? color : this.color,
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['rate'].previousValue !== changes['rate'].currentValue) {
            this.changeColorAndChecked(changes['rate'].currentValue);
        }
    }

    check(star: Star) {
        if (!this.isReadonly) {
            this.changeColorAndChecked(star.num);

            this.rate = star.num;
            this.rateChange.emit(star.num);
        }
    }

    changeColorAndChecked(num: number) {
        const color = this.getColor(num);

        this.stars = this.stars.map(s => {
            s.checked = s.num <= Math.round(this.rate);
            s.color = s.checked ? color : this.color;
            return s;
        });
    }

    getColor(num: number): Colors {
        if (num === 1) {
            return 'danger';
        } else if (num < 5) {
            return 'warn';
        } else {
            return 'success';
        }
    }
}
