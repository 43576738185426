import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiUserService } from '../../services/api-user/api-user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationService } from '../../../shared/services/translation/translation.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    form = new FormGroup({
        email: new FormControl('', [
            Validators.required,
            Validators.email
        ]),
        code: new FormControl('', Validators.required),
        password: new FormControl('', Validators.required),
    });

    constructor(
        private route: ActivatedRoute,
        private snackBar: MatSnackBar,
        private translationService: TranslationService,
        private apiUserService: ApiUserService,
        private router: Router) {
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(({ email }) => {
            this.form.patchValue({ email })
        });
    }

    resetPassword() {
        this.form.disable();

        const { email, code, password } = this.form.value as any;

        this.apiUserService.resetPassword(email, code, password).subscribe({
            next: async () => {
                this.snackBar.open(
                    await this.translationService.getTranslation('USER.PAGES.RESET_PASSWORD.TOAST.SUCCESS'),
                    await this.translationService.getTranslation('COMMON.CLOSE'),
                    { panelClass: 'background-success' }
                );

                // Reset form and redirect
                this.form.reset();
                await this.router.navigate(['/auth']);
            },
            error: () => {
                this.form.enable();
            }
        });
    }

    sendNewCode() {
        const email = this.form.get('email')?.value as string;
        this.apiUserService.createResetCode(email).subscribe(async () => {
            this.snackBar.open(
                await this.translationService.getTranslation('USER.PAGES.RESET_PASSWORD.TOAST.WARNING'),
                await this.translationService.getTranslation('COMMON.CLOSE'),
                { duration: 6000, panelClass: 'background-warn' }
            );
        });
    }

}
