import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ApiClientService } from '../api-client/api-client.service';
import { environment } from '../../../../environments/environment';
import { UrlBuilder } from '@innova2/url-builder';
import { PaginatedDataOutput } from '../../dtos/outputs/paginated-data.output';
import { ApiGetOptions, CACHE_TTL_DEFAULT, FindOptions } from '../../api.constants';

@Injectable({
    providedIn: 'root'
})
export class ApiService<O, I = any> {
    protected readonly baseUrl: string = environment.apiUrl;
    protected readonly resourceUri: string;

    constructor(protected readonly apiClient: ApiClientService) {
    }

    static initializeApiOptions = (url: UrlBuilder, ttl?: number): ApiGetOptions => ({
        cache: {
            group: url.getRelativePath(),
            ttl: ttl ?? CACHE_TTL_DEFAULT,
        },
    })

    findAll(opts?: FindOptions): Observable<O[]>;

    findAll(page: number, opts?: FindOptions): Observable<PaginatedDataOutput<O>>;

    findAll(pageOrOpts?: number | FindOptions, opts?: FindOptions): any {
        const options = (typeof pageOrOpts === 'number' ? opts : pageOrOpts) || {};
        const page = typeof pageOrOpts === 'number' ? pageOrOpts : null;

        const url = UrlBuilder.createFromUrl(this.baseUrl).addPath(this.resourceUri).addParams(options.params || {});
        if (page) {
            url.addQueryParam('page', page);
        }
        if (options.q) {
            if (typeof options.q === 'object') {
                for (const [key, value] of Object.entries(options.q)) {
                    url.addQueryParam(`q[${key}]`, value);
                }
            } else {
                url.addQueryParam('q', options.q);
            }
        }

        return this.apiClient
            .get<O[]>(url, ApiService.initializeApiOptions(url, options.ttl))
            .pipe(map((res) => res.body));
    }

    findById(id: string | number, opts: FindOptions = {}): Observable<O> {
        const url = UrlBuilder.createFromUrl(this.baseUrl)
            .addPath(this.resourceUri)
            .addPath(':id')
            .addParam('id', id)
            .addParams(opts.params || {})
            .addQueryParams(opts.query || {});

        return this.apiClient
            .get<O>(url, ApiService.initializeApiOptions(url, opts.ttl))
            .pipe(map((res) => res.body as O));
    }

    create(data: Partial<I>, params?: Record<string, string | number>,
           queries?: Record<string, string | number>, callLocation = false): Observable<O> {
        const url = UrlBuilder.createFromUrl(this.baseUrl).addPath(this.resourceUri).addParams(params || {});
        if (queries) {
            url.addQueryParams(queries);
        }

        return this.apiClient
            .post<O>(url, data, {}, callLocation)
            .pipe(map((res) => res.body as O));
    }

    createBulk(data: Partial<I>, params?: Record<string, string | number>): Observable<O> {
        return this.apiClient
            .post<O>(
                UrlBuilder.createFromUrl(this.baseUrl)
                    .addPath(this.resourceUri)
                    .addPath('bulk')
                    .addParams(params || {}),
                data,
            )
            .pipe(map((res) => res.body as O));
    }

    update(id: string, data: Partial<I>, params?: Record<string, string | number>): Observable<O> {
        const url = UrlBuilder.createFromUrl(this.baseUrl)
            .addPath(this.resourceUri)
            .addPath(':id')
            .addParam('id', id)
            .addParams(params || {});

        return this.apiClient
            .patch<O>(
                url,
                data,
            )
            .pipe(map((res) => res.body as O));
    }

    delete(id: string, params?: Record<string, string | number>, data?: Partial<I>): Observable<HttpResponse<void>> {
        return this.apiClient.delete<void>(
            UrlBuilder.createFromUrl(this.baseUrl)
                .addPath(this.resourceUri)
                .addPath(':id')
                .addParam('id', id)
                .addParams(params || {}),
            {},
            data
        );
    }

    getResourceUri(): string {
        return this.resourceUri;
    }
}
