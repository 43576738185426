<div class="chat-ui" *ngIf="user">
    <div class="chat-ui__messages">
        <app-chat-message *ngFor="let msg of messages | orderBy: 'createdAt': false" [message]="msg"
                          [authenticatedUser]="user" (retry)="retry(msg)"></app-chat-message>
    </div>
    <form class="chat-ui__form" [formGroup]="form" (ngSubmit)="sendMessage()">
        <mat-form-field appearance="fill">
            <mat-label>{{'SHARED.COMPONENTS.CHAT_UI.FORM.LABEL_MESSAGE' | translate}}</mat-label>
            <input matInput [placeholder]="'SHARED.COMPONENTS.CHAT_UI.FORM.PLACEHOLDER_MESSAGE' | translate" formControlName="message">
        </mat-form-field>
        <button mat-raised-button color="primary" [class.spinner]="form.disabled" [disabled]="form.disabled || form.invalid">
            <ion-icon name="send-outline"></ion-icon>
            <mat-progress-spinner *ngIf="form.disabled" mode="indeterminate" [diameter]="20" [strokeWidth]="1"></mat-progress-spinner>
        </button>
    </form>
</div>
