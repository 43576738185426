<div class="auth">
    <div class="auth__left">
        <div class="auth__left__container">
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="auth__right" (swipeleft)="handleSwipe('left')" (swiperight)="handleSwipe('right')">
        <ng-container *ngIf="features[activeFeature] as feature">
            <img class="auth__right__screen" [src]="'assets/screens/' + feature.screen + '.png'" alt="Envies"/>

            <h2 class="auth__right__title">{{feature.title | translate}}</h2>
            <p class="auth__right__description text-light" [innerHTML]="feature.description | translate"></p>

            <div class="auth__right__pagination">
                <ion-icon name="chevron-back" class="chevron" [class.disabled]="activeFeature === 0" (click)="handleSwipe('right')"></ion-icon>
                <ion-icon *ngFor="let f of features; let i = index" name="ellipse" class="ellipse"
                          [class.selected]="i === activeFeature" (click)="selectFeature(i)"></ion-icon>
                <ion-icon name="chevron-forward" class="chevron" [class.disabled]="activeFeature === (features.length - 1)" (click)="handleSwipe('left')"></ion-icon>
            </div>
        </ng-container>
    </div>
</div>
