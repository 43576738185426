import { createAction, props } from '@ngrx/store';
import { JwtTokenOutput } from '../dtos/outputs/jwt-token.output';

const init = createAction('[JWT] Init', props<JwtTokenOutput>());
const reset = createAction('[JWT] Reset');

export const JwtActions = {
    init,
    reset
};
