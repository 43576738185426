import { Injectable } from '@angular/core';
import { TranslationService } from '../../services/translation/translation.service';
import { ApiErrorOutput } from '../../dtos/outputs/api-error.output';

@Injectable({
    providedIn: 'root'
})
export class ApiResponseHelper {
    private static readonly TRAD_ARGS = 'API.ARGS.';
    private static readonly TRAD_INPUTS = 'API.INPUTS.';
    private static readonly TRAD_CODES = 'API.CODES.';

    constructor(private readonly translationService: TranslationService) {
    }

    /**
     * Map the ApiError to Alert
     * @param err the ApiErrorOutput from the HTTP response
     */
    async mapErrorToAlert(err: ApiErrorOutput): Promise<string> {
        // Translate arguments before using them as title parameters
        const args = err.args ?
            (await Promise.all(
                    Object.entries(err.args).map(async ([k, v]) =>
                        [k, await this.translationService.getTranslationOrDefault(ApiResponseHelper.TRAD_ARGS + v, v)],
                    ),
                )
            ).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
            : {};

        // Translate invalid input properties before using them as title parameters
        const invalidInputs = err.invalidInputs ? await Promise.all(
                err.invalidInputs.map(v => {
                    return this.translationService.getTranslationOrDefault(ApiResponseHelper.TRAD_INPUTS + v, v);
                }),
        ) : [];

        // Translate code to generate title
        const title = await this.translationService.getTranslationOrDefault(
            String(ApiResponseHelper.TRAD_CODES + err.code), err.detail || '', args || {},
        );

        // Merge title with invalid input properties
        return invalidInputs.length ? title + ` (${invalidInputs.join(', ')})` : title;
    }
}
