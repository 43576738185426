import { Component } from '@angular/core';

interface MainFeature {
    screen: string;
    title: string;
    description: string;
}

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})
export class AuthComponent {

    features: MainFeature[] = [
        {
            screen: 'owned',
            title: 'USER.PAGES.AUTH.OWNED.TITLE',
            description: 'USER.PAGES.AUTH.OWNED.DESCRIPTION'
        },
        {
            screen: 'desires',
            title: 'USER.PAGES.AUTH.DESIRES.TITLE',
            description: 'USER.PAGES.AUTH.DESIRES.DESCRIPTION'
        },
        {
            screen: 'groups',
            title: 'COMMON.GROUPS',
            description: 'USER.PAGES.AUTH.GROUPS.DESCRIPTION'
        },
        {
            screen: 'libraries',
            title: 'USER.PAGES.AUTH.LIBRARIES.TITLE',
            description: 'USER.PAGES.AUTH.LIBRARIES.DESCRIPTION'
        }
    ];

    activeFeature: number = 0;

    handleSwipe(direction: 'left' | 'right') {
        if (direction === 'right' && this.activeFeature > 0) {
            this.activeFeature--;
        } else if (direction === 'left' && this.activeFeature < (this.features.length - 1)) {
            this.activeFeature++;
        }
    }

    selectFeature(index: number) {
        this.activeFeature = index;
    }

}
