import { ActionReducer, INIT, MetaReducer } from '@ngrx/store';
import { AppActions } from './app.actions';

export function resetAll(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state, action) => {
        if (action != null && action.type === AppActions.resetAll.type) {
            return reducer( undefined, { type: INIT });
        }
        return reducer(state, action);
    };
}

export const appMetaReducers: MetaReducer[] = [ resetAll ];
