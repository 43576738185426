import { Injectable } from '@angular/core';
import { User } from '../../dtos/user';
import { ApiService } from '../../../shared/services/api/api.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UrlBuilder } from '@innova2/url-builder';
import { UserContextOutput } from '../../dtos/outputs/user-context.output';
import { API_RESOURCE_USERS } from '../../../shared/api.constants';

@Injectable({
    providedIn: 'root'
})
export class ApiUserService extends ApiService<User, User> {
    protected override readonly resourceUri = API_RESOURCE_USERS;

    findMe(): Observable<User> {
        return this.findById('me', { ttl: 0 });
    }

    activation(email: string, code: string) {
        return this.apiClient
            .patch<User>(
                UrlBuilder.createFromUrl(this.baseUrl)
                    .addPath(this.resourceUri)
                    .addPath(':email/activation')
                    .addParam('email', email),
                { code }
            )
            .pipe(map((res) => res.body));
    }

    generateNewActivationCode(email: string, lang: string) {
        return this.apiClient
            .patch<User>(
                UrlBuilder.createFromUrl(this.baseUrl)
                    .addPath(this.resourceUri)
                    .addPath(':email/activation-code')
                    .addParam('email', email)
                    .addQueryParam('lang', lang),
                {}
            )
            .pipe(map((res) => res.body));
    }

    createResetCode(email: string) {
        return this.apiClient
            .post<User>(
                UrlBuilder.createFromUrl(this.baseUrl)
                    .addPath(this.resourceUri)
                    .addPath(':email/reset-password')
                    .addParam('email', email),
                {}
            )
            .pipe(map((res) => res.body));
    }

    resetPassword(email: string, code: string, password: string) {
        return this.apiClient
            .patch<User>(
                UrlBuilder.createFromUrl(this.baseUrl)
                    .addPath(this.resourceUri)
                    .addPath(':email/password/:code')
                    .addParam('email', email)
                    .addParam('code', code),
                { password }
            )
            .pipe(map((res) => res.body));
    }

    findUserContext(username: string = 'me'): Observable<UserContextOutput> {
        const url = UrlBuilder.createFromUrl(this.baseUrl)
                .addPath(this.resourceUri)
                .addPath(':username/context')
                .addParam('username', username);

        return this.apiClient
            .get<UserContextOutput>(url, {
                cache: { group: url.getRelativePath(), ttl: 0 }
            })
            .pipe(map((res) => res.body as UserContextOutput));
    }
}
