<div class="reset-password">
    <h1 class="text-color-primary">{{'USER.PAGES.RESET_PASSWORD.TITLE' | translate}}</h1>
    <p class="reset-password__description text-italic">{{'USER.PAGES.RESET_PASSWORD.DESCRIPTION' | translate}}</p>

    <form class="reset-password__form" [formGroup]="form" (ngSubmit)="resetPassword()">
        <div class="reset-password__form__content">
            <mat-form-field appearance="fill">
                <mat-label>{{'USER.PAGES.RESET_PASSWORD.CODE' | translate}}</mat-label>
                <input type="string" required matInput formControlName="code">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{'USER.PAGES.RESET_PASSWORD.NEW_PASSWORD' | translate}}</mat-label>
                <input type="password" required matInput formControlName="password">
            </mat-form-field>

            <p class="text-light pointer" (click)="sendNewCode()">{{'USER.PAGES.RESET_PASSWORD.NEW_CODE' | translate}}</p>
        </div>

        <div class="reset-password__form__btns">
            <button mat-button class="reset-password__form__btns__home" routerLink="/">{{'USER.PAGES.RESET_PASSWORD.HOME' | translate}}</button>
            <button mat-button type="submit" color="primary"
                    class="reset-password__form__btns__submit"
                    [disabled]="form.invalid">
                <span *ngIf="form.enabled">{{'USER.COMPONENTS.CREATE_RESET_CODE.RESET' | translate}}</span>
                <mat-progress-spinner *ngIf="form.disabled" mode="indeterminate" [diameter]="20" [strokeWidth]="1"></mat-progress-spinner>
            </button>
        </div>
    </form>
</div>
