import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LightboxComponent } from '../lightbox/lightbox.component';
import { User } from '../../../user/dtos/user';
import { filter, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserModuleSelectors, UserModuleState } from '../../../user/user.state';
import { NavigationStart, Router } from '@angular/router';
import { StoreHelper } from '../../../user/helpers/store/store.helper';
import { UrlBuilder } from '@innova2/url-builder';
import { LARGE_MOBILE_SIZE, LARGE_TABLET_SIZE } from '../../global.constants';

interface Page {
    title: string;
    path: string;
    hashUrl?: string;
    onlyLogged: boolean;
    selected: boolean;
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

    @Input() homeActive = true;
    @Output() homeActiveChange = new EventEmitter<boolean>();

    pages: Page[] = [
        {
            title: 'SHARED.COMPONENTS.HEADER.ABOUT',
            path: '/',
            hashUrl: 'about',
            onlyLogged: false,
            selected: false,
        }, {
            title: 'SHARED.COMPONENTS.HEADER.FEATURES',
            path: '/',
            hashUrl: 'features',
            onlyLogged: false,
            selected: false,
        }, {
            title: 'SHARED.COMPONENTS.HEADER.CONTACT_US',
            path: '/',
            hashUrl: 'contact',
            onlyLogged: false,
            selected: false,
        }, {
            title: 'SHARED.COMPONENTS.HEADER.HOME',
            path: '/',
            onlyLogged: true,
            selected: false,
        }, {
            title: 'SHARED.COMPONENTS.HEADER.DASHBOARD',
            path: '/dashboard',
            onlyLogged: true,
            selected: false,
        }, {
            title: 'SHARED.COMPONENTS.HEADER.MY_COLLECTIONS',
            path: '/collections',
            onlyLogged: true,
            selected: false,
        }
    ];

    user: User;
    userSubscription: Subscription;

    scrolled = false;
    viewMode: 'desktop' | 'tablet' | 'mobile' = 'desktop';

    constructor(private dialog: MatDialog,
                private router: Router,
                private storeHelper: StoreHelper,
                private store: Store<UserModuleState>) {
    }

    ngOnInit(): void {
        this.userSubscription = this.store.select(UserModuleSelectors.selectUser).subscribe(user => this.user = user);

        // Check page by current URL
        this.checkCurrentPage(window.location.pathname);

        // Listen for change URL
        this.listenChangeUrl();

        // Check is desktop or mobile
        this.defineViewMode();
    }

    ngOnDestroy(): void {
        this.userSubscription.unsubscribe();
    }

    defineViewMode() {
        this.viewMode = window.innerWidth > LARGE_TABLET_SIZE ? 'desktop' : (window.innerWidth > LARGE_MOBILE_SIZE ? 'tablet' : 'mobile');
    }

    @HostListener('window:scroll')
    onScroll() {
        this.scrolled = window.scrollY > 25;
    }

    @HostListener('window:resize')
    onResize() {
        this.defineViewMode();
    }

    listenChangeUrl() {
        this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.checkCurrentPage(event.url);
            }
        });
    }

    checkCurrentPage(url: string) {
        const urlBuilder = UrlBuilder.createFromUrl(url);
        this.homeActive = urlBuilder.getRelativePath() === '';
        this.homeActiveChange.emit(this.homeActive);

        this.pages = this.pages.map(page => {
            const samePath = page.path === url.replace(/#.*/, '');
            const urlSplitted = url.split('/');
            const fragmentTag = (urlSplitted[urlSplitted.length - 1] || '').replace('#', '');

            return {
                ...page,
                selected: samePath && (!page.hashUrl || fragmentTag === page.hashUrl)
            };
        });
    }

    showQRCode() {
        this.dialog.open(LightboxComponent, {
            data: {
                title: 'MyBooksMemory',
                img: 'assets/misc/app-qr-code.png',
                imgWidth: '300px',
                imgHeight: '300px'
            }
        });
    }

    logout() {
        this.storeHelper.clearAll();
    }
}
