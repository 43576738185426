import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiUserService } from '../../services/api-user/api-user.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationService } from '../../../shared/services/translation/translation.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

    form = new FormGroup({
        username: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
        email: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.email] }),
        password: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
        confirmPassword: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    });

    constructor(private apiUserService: ApiUserService,
                private snackBar: MatSnackBar,
                private translationService: TranslationService,
                private router: Router) {
    }

    async signup(): Promise<any> {
        this.form.disable();

        const { username, email, password, confirmPassword } = this.form.value;

        if (password !== confirmPassword) {
            return this.snackBar.open(
                await this.translationService.getTranslation('USER.PAGES.REGISTER.TOAST.BAD_PASSWORDS'),
                await this.translationService.getTranslation('COMMON.CLOSE'),
                { duration: 6000, panelClass: 'background-warn' }
            );
        }

        this.apiUserService.create({ username, email, password }).subscribe({
            next: async () => {
                this.snackBar.open(
                    await this.translationService.getTranslation('USER.PAGES.REGISTER.TOAST.EMAIL_VALIDATION'),
                    await this.translationService.getTranslation('COMMON.CLOSE'),
                    { duration: 6000, panelClass: 'background-warn' }
                );

                // Reset form and redirect
                this.form.reset();
                await this.router.navigateByUrl('/auth');
            },
            error: () => {
                this.form.enable();
            }
        });
    }

}
