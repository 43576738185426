<div class="register">
    <h1 class="register__title text-color-primary">{{'USER.PAGES.REGISTER.TITLE' | translate}}</h1>
    <p class="register__description text-italic">{{'USER.PAGES.REGISTER.DESCRIPTION' | translate}}</p>

    <form class="register__form" [formGroup]="form" (ngSubmit)="signup()">
        <mat-form-field appearance="fill">
            <mat-label>{{'USER.PAGES.REGISTER.FORM.USERNAME' | translate}}</mat-label>
            <input matInput [placeholder]="'USER.PAGES.REGISTER.FORM.USERNAME' | translate" formControlName="username">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Email</mat-label>
            <input matInput [placeholder]="'USER.PAGES.LOGIN.FORM.EMAIL' | translate" formControlName="email">
            <mat-error *ngIf="form.controls.email.invalid">{{'USER.PAGES.LOGIN.FORM.INVALID_EMAIL' | translate}}</mat-error>
        </mat-form-field>

        <div class="register__form__passwords flex flex-justify-sb flex-gap-15">
            <mat-form-field appearance="fill">
                <mat-label>{{'COMMON.PASSWORD' | translate}}</mat-label>
                <input matInput placeholder="{{'COMMON.ENTER_PASSWORD' | translate}}" type="password" formControlName="password">
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>{{'USER.PAGES.REGISTER.FORM.CONFIRM_PASSWORD' | translate}}</mat-label>
                <input matInput placeholder="{{'USER.PAGES.REGISTER.FORM.CONFIRM_PASSWORD' | translate}}" type="password" formControlName="confirmPassword">
            </mat-form-field>
        </div>

        <div class="register__form__btns">
            <button mat-raised-button color="primary" class="text-light" [class.spinner]="form.disabled" [disabled]="form.disabled || form.invalid">
                <span *ngIf="form.enabled">{{'USER.PAGES.REGISTER.SIGN_UP' | translate | uppercase}}</span>
                <mat-progress-spinner *ngIf="form.disabled" mode="indeterminate" [diameter]="20" [strokeWidth]="1"></mat-progress-spinner>
            </button>
        </div>
    </form>

    <div class="register__app">
        <p class="register__app__download text-italic">
            {{'USER.PAGES.REGISTER.ALREADY_REGISTERED' | translate}} <a [routerLink]="['/auth']" class="text-semi-bold">{{'USER.PAGES.REGISTER.LOG_IN' | translate}}</a><br>
            <span [innerHTML]="'USER.PAGES.LOGIN.LOGIN_APP.DOWNLOAD' | translate: { url: 'https://play.google.com/store/apps/details?id=net.booksmemory.my&gl=FR' }"></span>
        </p>
        <img src="assets/misc/app-qr-code.png" alt="QRCode" class="register__app__qrcode">
    </div>
</div>
