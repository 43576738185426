<mat-toolbar class="footer" color="primary">
    <span class="text-light">
        {{'SHARED.COMPONENTS.FOOTER.LABEL.COPYRIGHTS' |translate}}
        - {{'SHARED.COMPONENTS.FOOTER.LABEL.DEVELOPERS' |translate}}
        - <a routerLink="/legal/privacy" class="text-color-accent">{{'SHARED.COMPONENTS.FOOTER.LABEL.CONFIDENTIALITY' |translate}}</a>
    </span>

    <div class="footer__icons" *ngIf="displaySocialNetworks">
        <a class="footer__icons__icon-block" [href]="socialNetworks.insta" target="_blank" rel="noopener">
            <ion-icon name="logo-instagram"></ion-icon>
        </a>
        <a class="footer__icons__icon-block" [href]="socialNetworks.fb" target="_blank" rel="noopener">
            <ion-icon name="logo-facebook"></ion-icon>
        </a>
        <a class="footer__icons__icon-block" [href]="socialNetworks.discord" target="_blank" rel="noopener">
            <ion-icon name="logo-discord"></ion-icon>
        </a>
    </div>
</mat-toolbar>
