import { CollectionTypeEnum } from '../collection/collection-type.enum';
import { PaginatedDataOutput } from './dtos/outputs/paginated-data.output';
import { PaginatedDataState } from '../app.state';
import { BookOutput } from '../book/dtos/outputs/book.output';
import { ColItemSeriesOutput } from '../collection/dtos/outputs/col-item-series.output';

// LocalStorage
export const LS_LANG = 'lang';
export const LS_PERSISTED_AUTH = 'persistedAuth';
export const LS_JWT = 'jwtToken';
export const LS_USER_ID = 'userId';
export const LS_EMAIL = 'email';
export const LS_NB_NOTIFICATIONS = 'numberNotifications';

// Social networks
export const SN_INSTA = 'https://www.instagram.com/mybooksmemory';
export const SN_DISCORD = 'https://discord.com/invite/NpfbyG3';
export const SN_FB = 'https://www.facebook.com/mybooksmemory';

// Lang
export const LANG_EN = 'en';
export const LANG_FR = 'fr';
export const LANG_ES = 'es';

// Pagination & List
export const EMPTY_ENTITY_PAGE = { totalItems: 0, totalPages: 1, currentPage: 1 };
export const EMPTY_PAGE: PaginatedDataOutput<any> = { ...EMPTY_ENTITY_PAGE, items: [] };
export const EMPTY_PAGE_LOADING: PaginatedDataState<any> = { ...EMPTY_PAGE, loadStatus: 'LOADING', loadedPages: [] };
export const EMPTY_PAGE_NOT_LOADED: PaginatedDataState<any> = { ...EMPTY_PAGE, loadStatus: 'NOT_LOADED', loadedPages: [] };

// Books & Collections
export const ALL_COLLECTIONS = [CollectionTypeEnum.OWN, CollectionTypeEnum.READ, CollectionTypeEnum.DESIRE];
export const ALL_COLLECTIONS_WITH_ICON = [{
    type: CollectionTypeEnum.OWN,
    icon: 'library'
}, {
    type: CollectionTypeEnum.READ,
    icon: 'eye'
}, {
    type: CollectionTypeEnum.DESIRE,
    icon: 'cart'
}];
export const DEFAULT_LIST_BY_COL_TYPE: { [type in CollectionTypeEnum]: string[] } = {
    [CollectionTypeEnum.OWN]: [],
    [CollectionTypeEnum.READ]: [],
    [CollectionTypeEnum.DESIRE]: [],
    [CollectionTypeEnum.FAVORITE]: [],
};
export const DEFAULT_PAGE_BY_COL_TYPE: { [key in CollectionTypeEnum]: PaginatedDataState<BookOutput | ColItemSeriesOutput> } = {
    COL_OWN: EMPTY_PAGE_LOADING,
    COL_READ: EMPTY_PAGE_LOADING,
    COL_DESIRE: EMPTY_PAGE_LOADING,
    COL_FAVORITE: EMPTY_PAGE_LOADING,
};

// Size
export const CLASSIC_MOBILE_SIZE = 425;
export const MEDIUM_MOBILE_SIZE = 500;
export const LARGE_MOBILE_SIZE = 768;
export const LARGE_TABLET_SIZE = 1024;
export const LARGE_SCREEN_SIZE = 1920;
