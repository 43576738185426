import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiUserService } from '../../services/api-user/api-user.service';
import { TranslationService } from '../../../shared/services/translation/translation.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-create-reset-code',
    templateUrl: './create-reset-code.component.html',
    styleUrls: ['./create-reset-code.component.scss']
})
export class CreateResetCodeComponent {

    form = new FormGroup({
        email: new FormControl('', [
            Validators.required,
            Validators.email
        ]),
    });

    constructor(private apiUserService: ApiUserService,
                private translationService: TranslationService,
                private snackBar: MatSnackBar,
                private router: Router) {
    }

    createResetCode() {
        const email = this.form.get('email')?.value;
        if (!email) {
            return;
        }

        this.apiUserService.createResetCode(email).subscribe(async () => {
            this.router.navigate(['/auth', 'reset-password'], { queryParams: { email } });
            this.form.reset();

            this.snackBar.open(
                await this.translationService.getTranslation('USER.PAGES.RESET_PASSWORD.TOAST.WARNING'),
                await this.translationService.getTranslation('COMMON.CLOSE'),
                { duration: 6000, panelClass: 'background-warn' }
            );
        });
    }
}
