<div class="activation-account">
    <h1 class="activation-account__title text-color-primary">{{'USER.PAGES.ACTIVATION_ACCOUNT.TITLE' | translate}}</h1>
    <p class="activation-account__description text-italic">{{'USER.PAGES.ACTIVATION_ACCOUNT.DESCRIPTION' | translate}}</p>

    <p class="activation-account__validating text-light"
       [class.text-color-primary]="active === activationResult.Loading || active === activationResult.Activated"
       [class.text-color-danger]="active !== activationResult.Loading && active !== activationResult.Activated">
        {{active | checkActivation | translate}}
    </p>
    <span class="text-italic text-light">{{'USER.PAGES.ACTIVATION_ACCOUNT.QRCODE' | translate}}</span>
    <img src="assets/misc/app-qr-code.png"
         alt="https://play.google.com/store/apps/details?id=net.booksmemory.my&hl=fr&gl=US">
</div>
