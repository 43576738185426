import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent } from './pages/auth/auth.component';
import { LoginComponent } from './pages/login/login.component';
import { RouterModule, Routes } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StoreModule } from '@ngrx/store';
import { userModuleEffects, userModuleFeatureKey, userModuleReducers } from './user.state';
import { ActivationAccountComponent } from './pages/activation-account/activation-account.component';
import { CheckActivationPipe } from './pipes/check-activation/check-activation.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import {
    FormModifyPasswordModalComponent
} from './modals/form-modify-password-modal/form-modify-password-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DeleteAccountModalComponent } from './modals/delete-account-modal/delete-account-modal.component';
import { EffectsModule } from '@ngrx/effects';
import { CreateResetCodeComponent } from './components/create-reset-code/create-reset-code.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { RegisterComponent } from './pages/register/register.component';

const routes: Routes = [
    {
        path: 'auth',
        component: AuthComponent,
        children: [
            {
                path: '',
                component: LoginComponent,
            },
            {
                path: 'signup',
                component: RegisterComponent,
            },
            {
                path: 'reset-password',
                component: ResetPasswordComponent,
            },
            {
                path: 'activation-account/:email/:code',
                component: ActivationAccountComponent,
            }
        ]
    },
    {
        path: 'account',
        component: UserProfileComponent,
    },
];

@NgModule({
    declarations: [
        AuthComponent,
        LoginComponent,
        ResetPasswordComponent,
        ActivationAccountComponent,
        CheckActivationPipe,
        UserProfileComponent,
        FormModifyPasswordModalComponent,
        DeleteAccountModalComponent,
        CreateResetCodeComponent,
        RegisterComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        StoreModule.forFeature(userModuleFeatureKey, userModuleReducers),
        EffectsModule.forFeature(userModuleEffects),
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        SharedModule,
        MatButtonModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        TranslateModule,
        MatCardModule,
        MatIconModule,
        MatDialogModule,
        MatTooltipModule,
        ClipboardModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UserModule {
}
