import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChatMessage } from '../../dtos/chat-message';
import { User } from '../../../user/dtos/user';

@Component({
    selector: 'app-chat-message',
    templateUrl: './chat-message.component.html',
    styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent {

    @Input() message: ChatMessage;
    @Input() authenticatedUser: User;
    @Output() retry = new EventEmitter();

}
