import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/services/api/api.service';
import { User } from '../../dtos/user';
import { JwtTokenOutput } from '../../dtos/outputs/jwt-token.output';
import { API_RESOURCE_AUTH } from 'src/app/shared/api.constants';

@Injectable({
    providedIn: 'root'
})
export class ApiAuthService extends ApiService<JwtTokenOutput, User> {
    protected override readonly resourceUri = API_RESOURCE_AUTH;
}
