<div class="create-reset-code">
    <form class="create-reset-code__form" [formGroup]="form" (ngSubmit)="createResetCode()">
        <h1 mat-dialog-title>{{'USER.COMPONENTS.CREATE_RESET_CODE.FORGOT_PASSWORD_TITLE' | translate}}</h1>
        <div mat-dialog-content>
            <p class="text-italic">{{'USER.COMPONENTS.CREATE_RESET_CODE.FORGOT_PASSWORD' | translate}}</p>
            <mat-form-field appearance="fill">
                <mat-label>Email</mat-label>
                <input type="email" required matInput formControlName="email">
            </mat-form-field>

            <p class="text-light">{{'USER.COMPONENTS.CREATE_RESET_CODE.RESET_PASSWORD' | translate}}</p>
        </div>

        <mat-dialog-actions align="end">
            <button mat-button [mat-dialog-close]="null">{{'COMMON.CLOSE' | translate}}</button>
            <button mat-button [mat-dialog-close]="true" type="submit" color="primary"
                    [disabled]="form.disabled || form.invalid">{{'USER.COMPONENTS.CREATE_RESET_CODE.RESET' | translate}}</button>
        </mat-dialog-actions>
    </form>
</div>
