import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, Subject } from 'rxjs';

@Component({
    selector: 'app-debounce-search',
    templateUrl: './debounce-search.component.html',
    styleUrls: ['./debounce-search.component.scss']
})
export class DebounceSearchComponent implements OnInit {

    @Input() time: number;
    @Input() search = '';
    @Output() searchChange = new EventEmitter<string>();

    searchInputUpdate = new Subject<string>();

    ngOnInit(): void {
        this.listenSearch();
    }

    listenSearch = () => this.searchInputUpdate.pipe(debounceTime(this.time)).subscribe(this.handleUpdateSearch);

    handleUpdateSearch = (search: string) => {
        if (search !== this.search) {
            this.search = search;
            this.searchChange.emit(search);
        }
    }

    reset = () => this.handleUpdateSearch('');
}
