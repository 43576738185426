import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { JwtActions } from '../actions/jwt.action';
import { JwtTokenOutput } from '../dtos/outputs/jwt-token.output';

export const jwtFeatureKey = 'JWT';

// @ts-ignore
export const initialState: JwtTokenOutput = null;

const reducer: ActionReducer<JwtTokenOutput> = createReducer(
    initialState,
    on(JwtActions.init, (state, { type, ...data }) => data),
    on(JwtActions.reset, () => initialState)
);

export function jwtReducer(state: JwtTokenOutput | undefined, action: Action) {
    return reducer(state, action);
}

