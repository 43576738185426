<h1 mat-dialog-title>{{'USER.MODALS.FORM_MODIFY_PASSWORD_MODAL.TITLE' | translate}}</h1>
<form mat-dialog-content [formGroup]="form">
    <mat-form-field appearance="fill">
        <mat-label>{{'USER.MODALS.FORM_MODIFY_PASSWORD_MODAL.OLD_PASSWORD.LABEL' | translate}}</mat-label>
        <input matInput type="password"
               [placeholder]="'USER.MODALS.FORM_MODIFY_PASSWORD_MODAL.OLD_PASSWORD.PLACEHOLDER' | translate"
               formControlName="oldPassword">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>{{'USER.MODALS.FORM_MODIFY_PASSWORD_MODAL.NEW_PASSWORD.LABEL' | translate}}</mat-label>
        <input matInput type="password" [placeholder]="'USER.MODALS.FORM_MODIFY_PASSWORD_MODAL.NEW_PASSWORD.PLACEHOLDER' | translate"
               formControlName="newPassword">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>{{'USER.MODALS.FORM_MODIFY_PASSWORD_MODAL.CONFIRM_NEW_PASSWORD.LABEL' | translate}}</mat-label>
        <input matInput type="password" [placeholder]="'USER.MODALS.FORM_MODIFY_PASSWORD_MODAL.CONFIRM_NEW_PASSWORD.PLACEHOLDER' | translate"
               formControlName="confirmPassword">
    </mat-form-field>
</form>
<div mat-dialog-actions [align]="'end'">
    <button mat-button [mat-dialog-close]="null">{{'COMMON.CLOSE' | translate}}</button>
    <button mat-button (click)="handleSubmit()" [disabled]="form.disabled || form.invalid"
            color="primary">{{'COMMON.VALIDATE' | translate}}</button>
</div>
