<button mat-icon-button class="text-color-default" [matMenuTriggerFor]="translationMenu">
    <ion-icon name="language-outline"></ion-icon>
</button>

<mat-menu #translationMenu="matMenu">
    <button *ngFor="let lang of languages" mat-menu-item
            [disabled]="lang.code === currentLang" (click)="handleSelectLang(lang)">
        <span>{{lang.label}}</span>
    </button>
</mat-menu>
