import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'partialRows',
})
export class PartialRowsPipe implements PipeTransform {
    transform(items: any[], currentPage: number, loadedPages: number[], limit = 20): any[] {
        if (!items.length) {
            return [];
        }

        const position = loadedPages.indexOf(currentPage);
        return items.slice(position * limit, (position + 1) * limit);
    }
}
