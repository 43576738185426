import { Injectable } from '@angular/core';
import { ApiUserService } from '../../services/api-user/api-user.service';
import { Store } from '@ngrx/store';
import { UserModuleState } from '../../user.state';
import { JwtTokenOutput } from '../../dtos/outputs/jwt-token.output';
import { StoreHelper } from '../store/store.helper';
import { LS_EMAIL, LS_JWT, LS_USER_ID } from '../../../shared/global.constants';
import { JwtActions } from '../../actions/jwt.action';
import { lastValueFrom } from 'rxjs';
import { UserContextActions } from '../../actions/user-context.action';
import { UserActions } from '../../actions/user.action';

@Injectable({
    providedIn: 'root'
})
export class AuthHelper {

    constructor(private apiUserService: ApiUserService,
                private storeHelper: StoreHelper,
                private store: Store<UserModuleState>) {
    }

    async saveAuthentication(storage: Storage, jwtToken: JwtTokenOutput): Promise<void> {
        // Make sure we don't have the old user's info
        await this.storeHelper.clearAll(false);

        // Store user info
        storage.setItem(LS_JWT, JSON.stringify(jwtToken));
        this.store.dispatch(JwtActions.init(jwtToken));

        try {
            const userContext = await lastValueFrom(this.apiUserService.findUserContext());
            this.store.dispatch(UserContextActions.initOrUpdate({ context: userContext }));

            const user = await lastValueFrom(this.apiUserService.findMe());
            storage.setItem(LS_USER_ID, user.id);
            storage.setItem(LS_EMAIL, user.email);
            this.store.dispatch(UserActions.init({ user }));
        } catch (e) {
            console.log('Session expirée', e);
            this.storeHelper.clearAll();
        }
    }
}
