<div class="chat-message"
     [class.chat-message--left-alignment]="authenticatedUser.id !== message.author.id"
     [class.chat-message--right-alignment]="authenticatedUser.id === message.author.id">
    <div class="chat-message__msg-container">
        <p class="chat-message__msg-container__message">
            {{message.message}}
        </p>
        <p class="chat-message__msg-container__loading text-light text-color-primary"
           *ngIf="message.status === 'LOADING' && authenticatedUser.id === message.author.id">
            {{'SHARED.COMPONENTS.CHAT_MESSAGE.LOADING' | translate}}
        </p>
        <p class="chat-message__msg-container__date text-light"
           *ngIf="message.status === 'SENT' || authenticatedUser.id !== message.author.id">
            {{message.createdAt | date: 'dd/MM/yyyy H:mm:ss'}}
        </p>
        <p class="chat-message__msg-container__error text-light text-color-danger"
           *ngIf="message.status === 'FAILED' && authenticatedUser.id === message.author.id">
            {{'SHARED.COMPONENTS.CHAT_MESSAGE.FAILED' | translate}}
            <span class="text-semi-light pointer" (click)="retry.emit()">
                {{'SHARED.COMPONENTS.CHAT_MESSAGE.SEND_RETRY' | translate}}
            </span>.
        </p>
    </div>
    <div class="chat-message__user-container">
        <img class="chat-message__user-container__avatar rounded" alt="Avatar"
             [src]="'assets/avatar/avatar-' + message.author.avatar + '.png'">
        <p class="chat-message__user-container__username">{{message.author.username}}</p>
    </div>
</div>
