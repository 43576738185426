import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { SelectTranslationComponent } from './components/select-translation/select-translation.component';
import { TranslateModule } from '@ngx-translate/core';
import { LightboxComponent } from './components/lightbox/lightbox.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { FilterByPipe } from './pipes/filter-by/filter-by.pipe';
import { SwitchLangLabelPipe } from './pipes/switch-lang-label/switch-lang-label.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { TruncatePipe } from './pipes/truncate/truncate.pipe';
import { LoadingComponent } from './components/loading/loading.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChatUiComponent } from './components/chat-ui/chat-ui.component';
import { ChatMessageComponent } from './components/chat-message/chat-message.component';
import { FooterComponent } from './components/footer/footer.component';
import { JoinPipe } from './pipes/join/join.pipe';
import { CensorPipe } from '../book/pipes/censor/censor.pipe';
import { PaginationComponent } from './components/pagination/pagination.component';
import { FooterPaginationComponent } from './components/footer-pagination/footer-pagination.component';
import { TranslateOrDefaultPipe } from './pipes/translate-or-default/translate-or-default.pipe';
import { BadgeComponent } from './components/badge/badge.component';
import { PartialRowsPipe } from './pipes/partial-rows/partial-rows.pipe';
import { NothingFoundComponent } from './components/nothing-found/nothing-found.component';
import { OrderPipe } from './pipes/order/order.pipe';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { StylingStarByRatePipe } from './pipes/styling-star-by-rate/styling-star-by-rate.pipe';
import { DebounceSearchComponent } from './components/debounce-search/debounce-search.component';

@NgModule({
    declarations: [
        HeaderComponent,
        SelectTranslationComponent,
        LightboxComponent,
        FilterByPipe,
        TruncatePipe,
        JoinPipe,
        LoadingComponent,
        SwitchLangLabelPipe,
        StylingStarByRatePipe,
        OrderPipe,
        ChatUiComponent,
        ChatMessageComponent,
        FooterComponent,
        PaginationComponent,
        FooterPaginationComponent,
        TranslateOrDefaultPipe,
        BadgeComponent,
        PartialRowsPipe,
        NothingFoundComponent,
        StarRatingComponent,
        DebounceSearchComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatToolbarModule,
        MatButtonModule,
        MatMenuModule,
        TranslateModule,
        MatDialogModule,
        MatSnackBarModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        FormsModule,
    ],
    exports: [
        HeaderComponent,
        OrderPipe,
        SwitchLangLabelPipe,
        ChatUiComponent,
        FooterComponent,
        TruncatePipe,
        JoinPipe,
        SelectTranslationComponent,
        LoadingComponent,
        PaginationComponent,
        FooterPaginationComponent,
        TranslateOrDefaultPipe,
        BadgeComponent,
        PartialRowsPipe,
        NothingFoundComponent,
        StarRatingComponent,
        DebounceSearchComponent,
        FilterByPipe
    ],
    providers: [CensorPipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
}
