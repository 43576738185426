import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationService } from '../../../shared/services/translation/translation.service';

@Component({
    selector: 'app-form-modify-password-modal',
    templateUrl: './form-modify-password-modal.component.html',
    styleUrls: ['./form-modify-password-modal.component.scss']
})
export class FormModifyPasswordModalComponent {

    form = new FormGroup({
        oldPassword: new FormControl('', { validators: [Validators.required] }),
        newPassword: new FormControl('', { validators: [Validators.required] }),
        confirmPassword: new FormControl('', { validators: [Validators.required] })
    });

    constructor(
        private snackBar: MatSnackBar,
        private translationService: TranslationService,
        private dialogRef: MatDialogRef<FormModifyPasswordModalComponent>
    ) {
    }

    async handleSubmit() {
        const { oldPassword, newPassword, confirmPassword } = this.form.value;

        if (newPassword !== confirmPassword) {
            return this.snackBar.open(
                await this.translationService.getTranslation('USER.MODALS.FORM_MODIFY_PASSWORD_MODAL.PASSWORDS_NO_MATCH'),
                await this.translationService.getTranslation('COMMON.CLOSE'),
            );
        }

        return this.dialogRef.close({ oldPassword, newPassword });
    }

}
