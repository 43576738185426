<ng-container *ngIf="mainLoading">
    <div class="main-loading">
        <h1 class="main-loading__title">MyBooksMemory</h1>
        <mat-spinner [strokeWidth]="5"></mat-spinner>
    </div>
</ng-container>

<ng-container *ngIf="!mainLoading">
    <app-header [(homeActive)]="homeActive"></app-header>

    <div class="page-loading" *ngIf="pageLoading">
        <mat-spinner [strokeWidth]="5"></mat-spinner>
    </div>

    <div id="page" class="page-container" [class.page-container--without-padding]="homeActive">
        <router-outlet></router-outlet>
    </div>

    <app-footer [displaySocialNetworks]="!homeActive"></app-footer>
</ng-container>
