<div class="delete-account">
    <h1>{{'USER.MODALS.DELETE_ACCOUNT_MODAL.TITLE' | translate}}</h1>
    <h4>{{'USER.MODALS.DELETE_ACCOUNT_MODAL.SUBTITLE' | translate}}</h4>
    <h5>{{'USER.MODALS.DELETE_ACCOUNT_MODAL.DESCRIPTION' | translate}}</h5>
    <form mat-dialog-content [formGroup]="form">
        <mat-form-field appearance="fill">
            <mat-label>{{'COMMON.PASSWORD' | translate}}</mat-label>
            <input matInput type="password" [placeholder]="'COMMON.ENTER_PASSWORD' | translate" formControlName="password">
        </mat-form-field>
    </form>
    <div mat-dialog-actions [align]="'end'">
        <button mat-button [mat-dialog-close]="null">{{'COMMON.CLOSE' | translate}}</button>
        <button mat-button (click)="handleSubmit()" [disabled]="form.disabled || form.invalid" color="primary">
            {{'COMMON.VALIDATE' | translate}}
        </button>
    </div>
</div>
