import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LANG_EN, LANG_ES, LANG_FR, LS_LANG } from '../../global.constants';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TranslationService {

    private readonly languages = [LANG_EN, LANG_FR, LANG_ES];

    constructor(private translateService: TranslateService) {
    }

    initializeLang() {
        this.translateService.addLangs(this.languages);
        this.translateService.setDefaultLang(LANG_FR);

        const lang = (localStorage.getItem(LS_LANG) || this.translateService.getBrowserLang()) as string;
        this.use(this.languages.includes(lang) ? lang : LANG_FR);
    }

    use(lang: string) {
        this.translateService.use(lang);
        localStorage.setItem(LS_LANG, lang);
    }

    getCurrentLang = () => this.translateService.currentLang;

    getTranslation = (key: string) => lastValueFrom(this.translateService.get(key));

    async getTranslationOrDefault(key: string, defaultValue: string, params?: any) {
        const value = await lastValueFrom(this.translateService.get(key, params));
        return value !== key ? value : defaultValue;
    }

    onLangChange = () => this.translateService.onLangChange;

}
