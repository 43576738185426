<mat-card class="account" *ngIf="user$ | async as user">
    <ng-container *ngIf="!user">
        <app-loading></app-loading>
    </ng-container>

    <div class="account__header">
        <div class="account__header__collections" *ngIf="context">
            <div class="account__header__collections__col pointer" *ngFor="let colType of allCollections"
                 (click)="handleColSelected(colType)">
                <span>{{context.nbBooksByCollection[colType]}}</span>
                <p class="account__header__collections__col__counter light">{{('COLLECTION.COMMON.' + colType) | translate}}</p>
            </div>
        </div>
        <div class="account__header__user__avatar">
            <img class="account__header__user__avatar-img" alt="Avatar"
                 [src]="'assets/avatar/avatar-' + (user ? user.avatar : 1) + '.png'">
            <h3 class="text-color-primary">{{user.username}}</h3>
            <span class="text-italic">{{'USER.PAGES.USER_PROFILE.USER' | translate}}</span><span
            class="text-color-primary text-italic">{{user.role.labelFr}}</span>
            <p class="text-italic account__header__user__avatar-email">{{user.email}}</p>
            <p class="account__header__user__avatar-profile">
                {{'USER.PAGES.USER_PROFILE.USER_PROFILE' | translate}}
                <span [class]="(user.isPublic ? 'text-color-primary' : 'text-color-danger') + ' account__header__user__avatar-profile-public'">{{user.isPublic ? ('COMMON.PUBLIC' | translate) : ('COMMON.PRIVATE' | translate)}}</span>
                <ion-icon *ngIf="user.isPublic" name="copy-outline" class="text-color-primary pointer"
                          [cdkCopyToClipboard]="publicProfileUrl + user.username"
                          [matTooltip]="'USER.PAGES.USER_PROFILE.COPY_LINK' | translate"></ion-icon>
            </p>
        </div>
        <div class="account__header__btns">
            <button mat-raised-button [color]="user.isPublic ? 'primary' : 'warn'" (click)="setPublic(user.isPublic)">
                <ion-icon [name]="user.isPublic ? 'lock-open-outline' : 'lock-closed-outline'"></ion-icon>
            </button>
            <button mat-raised-button color="primary" class="text-light" (click)="openEditPasswordDialog()">
                {{'USER.PAGES.USER_PROFILE.EDIT_PASSWORD' | translate | uppercase}}
            </button>
            <button mat-raised-button color="warn" (click)="openDeleteAccountDialog()">
                <ion-icon name="person-remove-outline"></ion-icon>
            </button>
        </div>
    </div>
    <mat-card-content class="account__ranking">
        <div class="account__ranking__podium">
            <div class="top-list-item second">
                <ng-container *ngIf="topRankingItems[1] as item">
                    <div class="item">
                        <p class="label">{{item.label | truncate: 20}}</p>
                        <p class="counter light">{{item.cnt}}</p>
                    </div>
                    <div class="podium">2</div>
                </ng-container>
            </div>
            <div class="top-list-item first">
                <ng-container *ngIf="topRankingItems[0] as item">
                    <div class="item">
                        <p class="label">{{item.label | truncate: 20}}</p>
                        <p class="counter light">{{item.cnt}}</p>
                    </div>
                    <div class="podium">1</div>
                </ng-container>
            </div>
            <div class="top-list-item third">
                <ng-container *ngIf="topRankingItems[2] as item">
                    <div class="item">
                        <p class="label">{{item.label | truncate: 20}}</p>
                        <p class="counter light">{{item.cnt}}</p>
                    </div>
                    <div class="podium">3</div>
                </ng-container>
            </div>
        </div>
    </mat-card-content>
</mat-card>
