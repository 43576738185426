import { createAction, props } from '@ngrx/store';
import { User } from '../dtos/user';

const init = createAction('[User] Init', props<{ user: User }>());
const updateAvatar = createAction('[User] Update Avatar', props<{ avatar: number }>());
const updatePublicProfile = createAction('[User] Update Public Profile', props<{ isPublic: boolean }>());
const reset = createAction('[User] Reset');

export const UserActions = {
    init,
    updateAvatar,
    updatePublicProfile,
    reset
};
