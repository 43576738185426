import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { User } from '../dtos/user';
import { UserActions } from '../actions/user.action';

export const userFeatureKey = 'User';

// @ts-ignore
export const initialState: User = null;

const reducer: ActionReducer<User> = createReducer(
    initialState,
    on(UserActions.init, (state, { user }) => user),
    on(UserActions.updateAvatar, (state, { avatar }) => ({ ...state, avatar })),
    on(UserActions.updatePublicProfile, (state, { isPublic }) => ({ ...state, isPublic })),
    on(UserActions.reset, () => initialState)
);

export function userReducer(state: User | undefined, action: Action) {
    return reducer(state, action);
}

