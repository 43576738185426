import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppActions } from '../../../app.actions';
import { LS_EMAIL, LS_JWT, LS_NB_NOTIFICATIONS, LS_USER_ID } from '../../../shared/global.constants';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class StoreHelper {

    constructor(
        private router: Router,
        private store: Store
    ) {
    }

    clearAll(redirectToAuth = true): void {
        localStorage.removeItem(LS_JWT);
        sessionStorage.removeItem(LS_JWT);
        localStorage.removeItem(LS_EMAIL);
        sessionStorage.removeItem(LS_EMAIL);
        localStorage.removeItem(LS_USER_ID);
        sessionStorage.removeItem(LS_USER_ID);
        localStorage.removeItem(LS_NB_NOTIFICATIONS);
        sessionStorage.removeItem(LS_NB_NOTIFICATIONS);

        this.store.dispatch(AppActions.resetAll());
        this.changeLoaderStatus(false);

        if (redirectToAuth) {
            this.router.navigateByUrl('/auth');
        }
    }

    changeLoaderStatus = (enable: boolean) => this.store.dispatch(AppActions.changeLoaderStatus({ enable }));

}
