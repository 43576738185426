import { Component, OnInit } from '@angular/core';
import { ApiUserService } from '../../services/api-user/api-user.service';
import { ActivatedRoute } from '@angular/router';

export enum ActivationResult {
    Loading,
    Activated,
    AlreadyActivated,
    NotActivated
}

@Component({
    selector: 'app-activation-account',
    templateUrl: './activation-account.component.html',
    styleUrls: ['./activation-account.component.scss']
})
export class ActivationAccountComponent implements OnInit {

    activationResult = ActivationResult;
    active = ActivationResult.Loading;

    constructor(private apiUserService: ApiUserService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.route.params.subscribe(params => this.activateAccount(params['email'], params['code']))
    }

    activateAccount(email: string, code: string) {
        this.apiUserService.activation(email, code).subscribe(() => {
            this.active = ActivationResult.Activated;
        }, ({ error }) => {
            if (error && error.code) {
                switch (error.code) {
                    case 'ERR_ACCOUNT_ALREADY_ACTIVATED':
                        this.active = ActivationResult.AlreadyActivated;
                        break;

                    default:
                        this.active = ActivationResult.NotActivated;
                }
            }
        });
    }

}
