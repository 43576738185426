import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'join'
})
export class JoinPipe implements PipeTransform {

    transform(values: any[], key: string, separator = ', '): string {
        return values.map(g => g[key]).join(separator);
    }

}
