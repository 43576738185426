<mat-toolbar class="header" [class.header--transparent]="homeActive && !scrolled" color="primary">
    <span class="header__title" routerLink="/">MyBooksMemory</span>

    <span class="header__spacer"></span>

    <!-- Desktop menu -->
    <ng-container *ngIf="viewMode === 'desktop'">
        <a *ngFor="let page of pages | filterBy: { onlyLogged: !!user }"
           mat-flat-button class="header__menu-item"
           [class.text-color-default]="!page.selected" [color]="page.selected || (homeActive && !scrolled) ? 'primary' : null"
           [routerLink]="page.path" [fragment]="page.hashUrl">
            {{page.title | translate}}
        </a>
    </ng-container>

    <span class="header__spacer"></span>

    <!-- Mobile menu -->
    <ng-container *ngIf="viewMode !== 'desktop'">
        <button mat-icon-button [matMenuTriggerFor]="mobileMenu" class="text-color-default">
            <ion-icon name="menu"></ion-icon>
        </button>
        <mat-menu #mobileMenu="matMenu">
            <button *ngFor="let page of pages | filterBy: { onlyLogged: !!user }" mat-menu-item
                    [disabled]="page.selected" [routerLink]="page.path" [fragment]="page.hashUrl">
                <span>{{page.title | translate}}</span>
            </button>
        </mat-menu>
    </ng-container>

    <div class="header__icon-buttons">
        <button mat-icon-button class="text-color-default" *ngIf="viewMode !== 'mobile'" (click)="showQRCode()">
            <ion-icon name="qr-code-outline"></ion-icon>
        </button>
        <app-select-translation></app-select-translation>
        <button mat-icon-button class="text-color-default"
                [routerLink]="user ? '/support' : '/'" [fragment]="!user ? 'contact' : undefined">
            <ion-icon name="bug-outline"></ion-icon>
        </button>
        <button mat-icon-button class="text-color-default" *ngIf="user" (click)="logout()" routerLink="/">
            <ion-icon name="log-out-outline"></ion-icon>
        </button>
    </div>
    <div class="header__user" [routerLink]="user ? '/account' : '/auth'">
        <button class="header__user__role-or-login" *ngIf="viewMode !== 'mobile'" mat-flat-button disableRipple>
            {{user ? (user.role | switchLangLabel) : ('SHARED.COMPONENTS.HEADER.LOGIN_LINK' | translate)}}
        </button>
        <img class="header__user__avatar rounded" [src]="'assets/avatar/avatar-' + (user ? user.avatar : 1) + '.png'"
             alt="Avatar">
    </div>
</mat-toolbar>
