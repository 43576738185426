<div class="login">
    <h1 class="login__title text-color-primary">{{'USER.PAGES.LOGIN.TITLE' | translate}}</h1>
    <p class="login__description text-italic">{{'USER.PAGES.LOGIN.DESCRIPTION' | translate}}</p>

    <form class="login__form" [formGroup]="form" (ngSubmit)="login()">
        <mat-form-field appearance="fill">
            <mat-label>Email</mat-label>
            <input matInput [placeholder]="'USER.PAGES.LOGIN.FORM.EMAIL' | translate" formControlName="email">
            <mat-error *ngIf="form.controls.email.invalid">{{'USER.PAGES.LOGIN.FORM.INVALID_EMAIL' | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>{{'COMMON.PASSWORD' | translate}}</mat-label>
            <input matInput placeholder="{{'COMMON.ENTER_PASSWORD' | translate}}" type="password" formControlName="password">
        </mat-form-field>

        <div class="login__form__btns">
            <mat-checkbox color="primary" formControlName="stayLogged">{{'COMMON.STAY_LOGGED_IN' | translate}}</mat-checkbox>
            <button mat-raised-button color="primary" class="text-light" [class.spinner]="form.disabled" [disabled]="form.disabled || form.invalid">
                <span *ngIf="form.enabled">{{'COMMON.TO_LOG_IN' | translate | uppercase}}</span>
                <mat-progress-spinner *ngIf="form.disabled" mode="indeterminate" [diameter]="20" [strokeWidth]="1"></mat-progress-spinner>
            </button>
        </div>
    </form>

    <div class="login__reset-code">
        <p class="text-italic pointer" (click)="createResetCode()">{{'USER.PAGES.LOGIN.FORGOT_PASSWORD' | translate}}</p>
    </div>

    <div class="login__app">
        <p class="login__app__download text-italic">
            {{'USER.PAGES.LOGIN.LOGIN_APP.NOT_REGISTERED' | translate}} <a [routerLink]="['/auth', 'signup']" class="text-semi-bold">{{'USER.PAGES.LOGIN.LOGIN_APP.REGISTER' | translate}}</a><br>
            <span [innerHTML]="'USER.PAGES.LOGIN.LOGIN_APP.DOWNLOAD' | translate: { url: 'https://play.google.com/store/apps/details?id=net.booksmemory.my&gl=FR' }"></span>
        </p>
        <img src="assets/misc/app-qr-code.png" alt="QRCode" class="login__app__qrcode">
    </div>
</div>
