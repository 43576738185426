import { mergeMap, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { UrlBuilder } from '@innova2/url-builder';
import { ApiResponseHelper } from '../../helpers/api-response/api-response.helper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationService } from '../translation/translation.service';
import { ApiService } from '../api/api.service';
import { CacheService } from 'ionic-cache';
import { CACHE_TTL_DEFAULT } from '../../api.constants';
import { Router } from '@angular/router';
import { StoreHelper } from '../../../user/helpers/store/store.helper';

@Injectable({
    providedIn: 'root'
})
export class ApiClientService {
    constructor(private httpClient: HttpClient,
                private storeHelper: StoreHelper,
                private router: Router,
                private apiResponseHelper: ApiResponseHelper,
                private translationService: TranslationService,
                private cacheService: CacheService,
                private snackBar: MatSnackBar) {
    }

    get<T>(url: UrlBuilder, opts = ApiService.initializeApiOptions(url, CACHE_TTL_DEFAULT)): Observable<HttpResponse<T>> {
        const { http, cache } = opts;
        const call = this.call<T>('get', url, http || {});

        return cache.ttl > 0
            ? this.cacheService.loadFromObservable(url.getRelativePath(true), call, cache.group, cache.ttl)
            : call;
    }

    post<T>(
        url: UrlBuilder,
        data: any,
        options: any = {},
        callLocation = false,
    ): Observable<HttpResponse<T>> {
        return this.call<T>('post', url, options, data).pipe(
            mergeMap((res) => {
                if (!callLocation) {
                    return of(res);
                }
                const path = res.headers.get('location') || '';
                const getUrl = UrlBuilder.createFromUrl(url.toString()).setPathSegments([path.replace(/^\/+/g, '')]);
                return this.get<T>(getUrl, { cache: { ttl: 0, group: getUrl.getRelativePath() } });
            }),
        );
    }

    put<T>(
        url: UrlBuilder,
        data: any,
        options: any = {},
    ): Observable<HttpResponse<T>> {
        return this.call<T>('put', url, options, data);
    }

    patch<T>(
        url: UrlBuilder,
        data: any,
        options: any = {},
    ): Observable<HttpResponse<T>> {
        return this.call<T>('patch', url, options, data);
    }

    delete<T>(
        url: UrlBuilder,
        options: any = {},
        data?: any,
    ): Observable<HttpResponse<T>> {
        return this.call<T>('delete', url, options, data);
    }

    private call<T>(
        method: string,
        url: UrlBuilder,
        options: any = {},
        data?: any,
    ): Observable<HttpResponse<T>> {
        let observable: Observable<HttpResponse<T>>;

        if (!options.headers) {
            options.headers = {};
        }

        options.observe = 'response';
        options.headers['Content-Type'] = 'application/json';
        // TODO : autoriser CORS -> options.headers['X-API-Version'] = environment.apiVersion;

        if (['post', 'put', 'patch'].includes(method)) {
            // @ts-ignore
            observable = this.httpClient[method]<T>(
                url.toString(),
                data,
                options,
            );
        } else {
            if (data) {
                options.body = data;
            }
            // @ts-ignore
            observable = this.httpClient[method]<T>(url.toString(), options);
        }

        return observable.pipe(
            tap(() => console.log(method.toUpperCase() + ' ' + url.toString() + ' : success')),
            catchError(async (err) => {
                console.log(method.toUpperCase() + ' ' + url.toString() + ' : error');

                if (err.error) {
                    if (err.error.status === HttpStatusCode.Unauthorized) {
                        this.storeHelper.clearAll();
                    }

                    if (err.error.code !== undefined) {
                        this.snackBar.open(
                            await this.apiResponseHelper.mapErrorToAlert(err.error),
                            'X',
                            { duration: 6000, panelClass: 'background-danger' }
                        );

                        if (err.error.code === 'ERR_NON_PUBLIC_PROFILE') {
                            this.router.navigateByUrl('/');
                        }

                        this.storeHelper.changeLoaderStatus(false);

                        throw err;
                    }
                }

                this.snackBar.open(await this.translationService.getTranslation('API.CODES.ERR_INTERNAL_ERROR'), 'Close', {  })

                throw err;
            }),
        );
    }
}
