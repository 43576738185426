import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/services/api/api.service';
import { CategoryOutput } from '../../dtos/outputs/category.output';
import { API_RESOURCE_CATEGORIES } from '../../../shared/api.constants';

@Injectable({
    providedIn: 'root',
})
export class ApiCategoryService extends ApiService<CategoryOutput> {
    protected override readonly resourceUri = API_RESOURCE_CATEGORIES;
}
