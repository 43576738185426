import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { mergeMap, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserModuleSelectors, UserModuleState } from '../../user.state';
import { first } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private store: Store<UserModuleState>) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.store.select(UserModuleSelectors.selectJWT).pipe(
            first(),
            mergeMap(jwt => {
                if (jwt) {
                    req = req.clone({
                        setHeaders: {
                            Authorization: `Bearer ${jwt.accessToken}`
                        }
                    });
                }

                return next.handle(req);
            })
        );
    }

}
