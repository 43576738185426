import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { first, map, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { UserContextActions } from '../actions/user-context.action';
import { UserModuleSelectors, UserModuleState } from '../user.state';
import { UserContextOutput } from '../dtos/outputs/user-context.output';

@Injectable()
export class UserContextEffects {
    incrementBooksByColTypeAndCategory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserContextActions.incrementBooksByColTypeAndCategory),
            mergeMap((props) =>
                this.store.select(UserModuleSelectors.selectUserContext).pipe(first(), map(context => ({
                    context,
                    props
                })))
            ),
            mergeMap(({ context, props }) => {
                const nbBooksByCategoryAndCol = context.nbBooksByCategoryAndCollection.filter(counter =>
                    counter.categoryId !== props.categoryId || counter.type !== props.colType
                );
                const counterForCategoryAndCol = context.nbBooksByCategoryAndCollection.find(counter =>
                    counter.categoryId === props.categoryId && counter.type === props.colType
                ) || { type: props.colType, categoryId: props.categoryId, cnt: 0 };

                const newContext: UserContextOutput = {
                    ...context,
                    nbBooksByCollection: {
                        ...context.nbBooksByCollection,
                        [props.colType]: context.nbBooksByCollection[props.colType] + props.cnt
                    },
                    nbBooksByCategoryAndCollection: [
                        ...nbBooksByCategoryAndCol,
                        {
                            ...counterForCategoryAndCol,
                            cnt: counterForCategoryAndCol.cnt + props.cnt,
                        }
                    ],
                };

                return [UserContextActions.initOrUpdate({ context: newContext })];
            })
        )
    );

    decrementBooksByColTypeAndCategory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserContextActions.decrementBooksByColTypeAndCategory),
            mergeMap((props) =>
                this.store.select(UserModuleSelectors.selectUserContext).pipe(first(), map(context => ({
                    context,
                    props
                })))
            ),
            mergeMap(({ context, props }) => {
                const nbBooksByCategoryAndCol = context.nbBooksByCategoryAndCollection.filter(counter =>
                    counter.categoryId !== props.categoryId || counter.type !== props.colType
                );
                const counterForCategoryAndCol = context.nbBooksByCategoryAndCollection.find(counter =>
                    counter.categoryId === props.categoryId && counter.type === props.colType
                ) || { type: props.colType, categoryId: props.categoryId, cnt: props.cnt };

                const newContext: UserContextOutput = {
                    ...context,
                    nbBooksByCollection: {
                        ...context.nbBooksByCollection,
                        [props.colType]: context.nbBooksByCollection[props.colType] - props.cnt
                    },
                    nbBooksByCategoryAndCollection: [
                        ...nbBooksByCategoryAndCol,
                        {
                            ...counterForCategoryAndCol,
                            cnt: counterForCategoryAndCol.cnt - props.cnt,
                        }
                    ],
                };

                return [UserContextActions.initOrUpdate({ context: newContext })];
            })
        )
    );

    constructor(
        private actions$: Actions,
        private store: Store<UserModuleState>,
    ) {
    }
}
